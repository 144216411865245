import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const {
  colorBorderActionSecondary,
  colorBgActionPrimaryActive,
  colorTextActionPrimary,
  borderWidth2,
  borderRadiusFull,
  space6,
  space16,
} = theme();

const HEIGHT = space16;

const style = StyleSheet.create({
  button: {
    alignContent: "center",
    borderColor: colorBorderActionSecondary,
    borderRadius: borderRadiusFull,
    borderWidth: borderWidth2,
    flex: 1,
    height: HEIGHT,
    justifyContent: "center",
    marginHorizontal: 12,
    paddingLeft: space6,
    paddingRight: space6,
  },
  buttonActive: {
    backgroundColor: colorBgActionPrimaryActive,
  },
  textActive: {
    color: colorTextActionPrimary,
  },
});

export { style };
