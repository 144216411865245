import React, { FC, useCallback, useMemo } from "react";
import { View } from "react-native";
import { Text, useDevice } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Country } from "@lookiero/sty-psp-locale";
import { RenderItemFunction, Tabs } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../../../domain/checkoutItem/model/checkoutItem";
import {
  CheckoutItemProductVariantProjection,
  CheckoutItemProjection,
} from "../../../../../../projection/checkoutItem/checkoutItem";
import { PriceProjection } from "../../../../../../projection/price/price";
import { DOMAIN, I18nMessages } from "../../../../i18n/i18n";
import { ProductVariant, ProductVariantStyle } from "../../../shared/components/productVariant/ProductVariant";
import { style } from "./CheckoutItemsTabs.style";

interface CheckoutItemProps {
  readonly checkoutItemStatus: CheckoutItemStatus;
  readonly checkoutItemProductVariant: CheckoutItemProductVariantProjection;
  readonly checkoutItemPrice: PriceProjection;
  readonly country: Country;
  readonly testID?: string;
  readonly onPress: () => void;
  readonly style?: Partial<ProductVariantStyle>;
}
const CheckoutItem: FC<CheckoutItemProps> = ({
  checkoutItemStatus,
  checkoutItemProductVariant,
  checkoutItemPrice,
  country,
  testID,
  style: customStyle,
  onPress,
}) => (
  <View testID={testID}>
    <ProductVariant
      brand={checkoutItemProductVariant.brand}
      color={checkoutItemProductVariant.color}
      country={country}
      media={checkoutItemProductVariant.media}
      name={checkoutItemProductVariant.name}
      price={checkoutItemPrice}
      size={checkoutItemProductVariant.size}
      status={checkoutItemStatus}
      style={customStyle}
      onPress={onPress}
    />
  </View>
);

interface CheckoutItemsTabsProps {
  readonly tabIndex: number;
  readonly checkoutItemsKept: CheckoutItemProjection[];
  readonly checkoutItemsReturned: CheckoutItemProjection[];
  readonly country: Country;
  readonly onPressItem: (checkoutItemId: string) => void;
  readonly onChanged?: (index: number) => void;
}
const CheckoutItemsTabs: FC<CheckoutItemsTabsProps> = ({
  tabIndex,
  checkoutItemsKept,
  checkoutItemsReturned,
  country,
  onPressItem,
  onChanged,
}) => {
  const { screen } = useDevice();
  const keepTabText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_KEEP_TAB });
  const returnTabText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_RETURN_TAB });

  const keepEmptyText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_KEEP_EMPTY });
  const returnEmptyText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_RETURN_EMPTY });

  const handleOnPressItem = useCallback((checkoutItemId: string) => onPressItem(checkoutItemId), [onPressItem]);

  const data = useMemo(() => [{ id: "0" }, { id: "1" }], []);
  const renderItem: RenderItemFunction<{ readonly id?: string }> = useCallback(
    ({ index }) =>
      index === 0 ? (
        checkoutItemsKept.length === 0 ? (
          <Text style={style.emptyText}>{keepEmptyText}</Text>
        ) : (
          <>
            {checkoutItemsKept.map((checkoutItem) => (
              <CheckoutItem
                key={checkoutItem.id}
                checkoutItemPrice={checkoutItem.price}
                checkoutItemStatus={checkoutItem.status}
                country={country}
                testID="keep-checkout-item"
                checkoutItemProductVariant={
                  checkoutItem.status === CheckoutItemStatus.REPLACED && checkoutItem.replacedFor
                    ? checkoutItem.replacedFor
                    : checkoutItem.productVariant
                }
                onPress={() => handleOnPressItem(checkoutItem.id)}
              />
            ))}
          </>
        )
      ) : checkoutItemsReturned.length === 0 ? (
        <Text style={style.emptyText}>{returnEmptyText}</Text>
      ) : (
        <>
          {checkoutItemsReturned.map((checkoutItem) => (
            <CheckoutItem
              key={checkoutItem.id}
              checkoutItemPrice={checkoutItem.price}
              checkoutItemProductVariant={checkoutItem.productVariant}
              checkoutItemStatus={checkoutItem.status}
              country={country}
              testID="return-checkout-item"
              onPress={() => handleOnPressItem(checkoutItem.id)}
            />
          ))}
        </>
      ),
    [checkoutItemsKept, checkoutItemsReturned, country, handleOnPressItem, keepEmptyText, returnEmptyText],
  );

  return (
    <Tabs
      data={data}
      defaultIndex={tabIndex}
      tabLabels={[`${keepTabText} (${checkoutItemsKept.length})`, `${returnTabText} (${checkoutItemsReturned.length})`]}
      style={{
        carousel: [style.carousel, screen.L && style.carouselDesktop],
        tabList: { tabList: [style.tabList, screen.L && style.tabListDesktop], tab: { tabText: style.tabText } },
      }}
      onChanged={onChanged}
    >
      {renderItem}
    </Tabs>
  );
};

export { CheckoutItemsTabs };
