import React, { FC, ReactNode } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { PricingProjection } from "../../../../../../projection/pricing/pricing";
import { Price } from "../../../../components/atoms/price/Price";
import { DOMAIN, I18nMessages } from "../../../../i18n/i18n";
import { style } from "./Pricing.style";

interface RowProps {
  readonly text: string;
  readonly level?: 1 | 2 | 3;
  readonly children: ReactNode;
  readonly action?: boolean;
}
const Row: FC<RowProps> = ({ action = false, text, level = 3, children }) => (
  <View style={style.row}>
    <Text action={action} level={level}>
      {text}
    </Text>
    {children}
  </View>
);

interface PricingProps {
  readonly pricing: PricingProjection;
  readonly totalCheckoutItemsKept: number;
}

const Pricing: FC<PricingProps> = ({ pricing, totalCheckoutItemsKept }) => {
  const { pendingToPay, subtotal, balanceDiscount, discount, discountPercentage = 0, service } = pricing;

  const totalText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_TOTAL });
  const totalCheckoutItemsKeptText = useI18nMessage({
    domain: DOMAIN,
    id: I18nMessages.SUMMARY_TOTAL_ITEMS_KEPT,
    values: { items: totalCheckoutItemsKept.toString() },
  });
  const subtotalText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_SUBTOTAL });
  const freeText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_FREE });
  const discountText = useI18nMessage({
    domain: DOMAIN,
    id: I18nMessages.SUMMARY_DISCOUNT,
    values: { discount: discountPercentage?.toString() },
  });
  const creditText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_CREDIT });
  const feeText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.SUMMARY_FEE });

  return (
    <View>
      <Row text={`${subtotalText} ${totalCheckoutItemsKeptText}`}>
        <Price price={subtotal} variant="subtotal" />
      </Row>

      {discount && discount.amount !== 0 && (
        <Row text={discountText}>
          <Price price={discount} variant="subtotal" />
        </Row>
      )}

      {balanceDiscount && balanceDiscount.amount !== 0 && (
        <Row text={creditText}>
          <Price price={balanceDiscount} variant="subtotal" />
        </Row>
      )}

      <Row text={feeText}>
        {service.paidWithPromocode ? (
          <Text level={3}>{freeText}</Text>
        ) : (
          <Price price={service.finalPrice} variant="subtotal" />
        )}
      </Row>

      <View style={style.divider} />

      <Row level={2} text={totalText} action>
        <Price price={pendingToPay} variant="total" />
      </Row>
    </View>
  );
};

export { Pricing };
