import React, { useCallback } from "react";
import { TouchableOpacity } from "react-native";
import { ALIGN, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { DOMAIN } from "../../../../../i18n/i18n";
import { useCheckoutQuestionFeedbackForId } from "../../behaviors/useCheckoutQuestionFeedback";
import { CheckoutQuestionItem, CheckoutQuestionItemProps } from "../CheckoutQuestionItem";
import { style } from "./ButtonCheckoutQuestionItem.style";

const ACTIVE_OPACITY = 0.6;

const ButtonCheckoutQuestionItem: CheckoutQuestionItem = ({
  checkoutQuestion,
  checkoutQuestionParentId,
  testID,
}: CheckoutQuestionItemProps) => {
  const { feedback, onChange } = useCheckoutQuestionFeedbackForId({ id: checkoutQuestionParentId });

  const optionText = useI18nMessage({ domain: DOMAIN, id: checkoutQuestion.name });
  const handleOnPress = useCallback(
    () => onChange({ checkoutQuestionId: checkoutQuestionParentId, checkoutQuestionFeedback: checkoutQuestion.id }),
    [onChange, checkoutQuestion.id, checkoutQuestionParentId],
  );

  return (
    <TouchableOpacity
      accessibilityLabel={testID}
      activeOpacity={ACTIVE_OPACITY}
      style={[style.button, feedback === checkoutQuestion.id && style.buttonActive]}
      testID={testID}
      onPress={handleOnPress}
    >
      <Text align={ALIGN.CENTER} level={3} style={feedback === checkoutQuestion.id && style.textActive} action>
        {optionText}
      </Text>
    </TouchableOpacity>
  );
};

export { ButtonCheckoutQuestionItem };
