import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { fontMap } = theme();

const style = StyleSheet.create({
  textBold: {
    fontFamily: fontMap.Action,
  },
});

export { style };
