/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from "react";
import { View } from "@lookiero/aurora";
import { useI18n } from "@lookiero/i18n-react";
import { InputField, TextInput } from "@lookiero/sty-psp-ui";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import { DOMAIN } from "../../../../../i18n/i18n";
import { useReturnQuestionFeedbackForReturnQuestion } from "../../behaviors/useReturnQuestionFeedback";
import { ReturnQuestionItem } from "../ReturnQuestionItem";
import { style } from "./TextareaReturnQuestionItem.style";

const TextareaReturnQuestionItem: ReturnQuestionItem<ReturnQuestionType.TEXTAREA> = ({
  returnQuestion,
  returnQuestionParent,
  testID,
}) => {
  const { formatMessage } = useI18n({ domain: DOMAIN });

  const placeholderText = useMemo(
    () => (returnQuestion.metadata.placeholder ? formatMessage({ id: returnQuestion.metadata.placeholder }) : ""),
    [formatMessage, returnQuestion.metadata.placeholder],
  );

  const { feedback, onChange } = useReturnQuestionFeedbackForReturnQuestion({ returnQuestion: returnQuestionParent });
  const handleOnChange = useCallback(
    (value: string | undefined) =>
      onChange({ returnQuestionId: returnQuestionParent.id, returnQuestionFeedback: value }),
    [onChange, returnQuestionParent.id],
  );

  return (
    <View style={style.wrapper} testID="textarea-return-question-item">
      <InputField
        label={placeholderText}
        value={feedback}
        input={({ onBlur, onFocus, style }) => (
          <TextInput
            placeholder={placeholderText}
            style={style}
            testID={testID}
            textAlignVertical="top"
            value={feedback}
            multiline
            onBlur={onBlur}
            onChange={handleOnChange}
            onFocus={onFocus}
          />
        )}
        style={{
          inputField: style.inputField,
          input: { container: style.inputContainer, input: style.input },
        }}
      />
    </View>
  );
};

export { TextareaReturnQuestionItem };
