// TODO properly export MessagingRoot from @lookiero/messaging-react
import React, { ComponentType, useCallback } from "react";
import { Platform } from "react-native";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { I18n } from "@lookiero/i18n-react";
import { QueryBus } from "@lookiero/messaging";
import { MessagingRoot } from "@lookiero/messaging-react/bootstrap";
import { Locale } from "@lookiero/sty-psp-locale";
import { SentryEnvironment, SentryLoggerFunctionArgs, sentryLogger, sentryLoggerHOC } from "@lookiero/sty-psp-logging";
import { Layout } from "@lookiero/sty-psp-ui";
import { Customer } from "../../projection/customer/customer";
import { Order } from "../../projection/order/order";
import { Subscription } from "../../projection/subscription/subscription";
import { KameleoonEnvironment } from "../ab-testing/kameleoonEnvironment";
import { QueryBusProvider } from "./hooks/useQueryBus";
import { Routing } from "./routing/Routing";

interface RootFunctionArgs {
  readonly Messaging: MessagingRoot;
  readonly I18n: I18n;
  readonly queryBus: QueryBus;
  readonly development?: boolean;
  readonly sentry: () => SentryEnvironment;
  readonly getAuthToken: () => Promise<string>;
  readonly kameleoon: () => KameleoonEnvironment;
}

interface RootFunction {
  (args: RootFunctionArgs): (args: SentryLoggerFunctionArgs) => ComponentType<RootProps>;
}

interface RootProps {
  readonly basePath: string;
  readonly locale?: Locale;
  readonly customer: Customer;
  readonly order: Order | undefined;
  readonly subscription: Subscription | undefined;
  readonly layout: Layout;
  readonly onNotAccessible: () => void;
  readonly onCheckoutSubmitted?: () => void;
  readonly useRedirect: () => Record<string, string>;
  readonly useRoutes?: typeof reactRouterUseRoutes;
}

const root: RootFunction = ({
  Messaging,
  I18n,
  queryBus,
  getAuthToken,
  development,
  sentry,
  kameleoon: kameleoonConfig,
}) => {
  const logger = sentryLogger(sentry);
  const kameleoon = kameleoonConfig();

  // eslint-disable-next-line react/display-name, react/prop-types
  const Root = ({
    basePath,
    locale = Locale.en_GB,
    customer,
    order,
    subscription,
    layout,
    onNotAccessible,
    onCheckoutSubmitted,
    useRedirect,
    useRoutes = reactRouterUseRoutes,
  }: RootProps) => {
    const handleOnI18nError = useCallback((error: Error) => logger.captureException(error), []);

    return (
      <Messaging includeReactQueryDevTools={Platform.OS === "web"}>
        <QueryBusProvider queryBus={queryBus}>
          <Routing
            I18n={I18n}
            basePath={basePath}
            customer={customer}
            getAuthToken={getAuthToken}
            kameleoon={kameleoon}
            layout={layout}
            locale={locale}
            order={order}
            subscription={subscription}
            useRedirect={useRedirect}
            useRoutes={useRoutes}
            onCheckoutSubmitted={onCheckoutSubmitted}
            onI18nError={development ? undefined : handleOnI18nError}
            onNotAccessible={onNotAccessible}
          />
        </QueryBusProvider>
      </Messaging>
    );
  };

  const hoc = sentryLoggerHOC({ logger });

  /**
   * It's important to not call sentryLoggerHOC each time this function gets called.
   * That's why its called outside this method.
   */
  return ({ customerId }) => hoc({ customerId })(Root);
};

export type { RootProps };
export { root };
