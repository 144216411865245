import React, { useCallback, useMemo } from "react";
import { useI18n } from "@lookiero/i18n-react";
import { InputField, TextInput } from "@lookiero/sty-psp-ui";
import { DOMAIN } from "../../../../../i18n/i18n";
import { useCheckoutQuestionFeedbackForId } from "../../behaviors/useCheckoutQuestionFeedback";
import { CheckoutQuestionItem, CheckoutQuestionItemProps } from "../CheckoutQuestionItem";
import { style } from "./TextareaCheckoutQuestionItem.style";

const TextareaCheckoutQuestionItem: CheckoutQuestionItem = ({
  checkoutQuestion,
  checkoutQuestionParentId,
  testID,
}: CheckoutQuestionItemProps) => {
  const { formatMessage } = useI18n({ domain: DOMAIN });
  const placeholderText = useMemo(
    () => (checkoutQuestion.placeholder ? formatMessage({ id: checkoutQuestion.placeholder }) : ""),
    [formatMessage, checkoutQuestion.placeholder],
  );

  const { feedback, onChange } = useCheckoutQuestionFeedbackForId({ id: checkoutQuestionParentId });
  const handleOnChange = useCallback(
    (value: string | undefined) =>
      onChange({ checkoutQuestionId: checkoutQuestionParentId, checkoutQuestionFeedback: value }),
    [onChange, checkoutQuestionParentId],
  );

  return (
    <InputField
      label={placeholderText}
      value={feedback}
      input={({ onBlur, onFocus, style }) => (
        <TextInput
          placeholder={placeholderText}
          style={style}
          testID={testID}
          textAlignVertical="top"
          value={feedback}
          multiline
          onBlur={onBlur}
          onChange={handleOnChange}
          onFocus={onFocus}
        />
      )}
      style={{
        inputField: style.inputField,
        input: { container: style.inputContainer, input: style.input },
      }}
    />
  );
};

export { TextareaCheckoutQuestionItem };
