import React, { FC, useCallback, useMemo, useState } from "react";
import { LayoutRectangle, View } from "react-native";
import { Button, BUTTON_VARIANT } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Country } from "@lookiero/sty-psp-locale";
import { Sticky } from "@lookiero/sty-psp-ui";
import { ProductVariantProjection } from "../../../../../../projection/bookedProductsVariants/bookedProductsVariants";
import { size } from "../../../../../../projection/size/size";
import { Body } from "../../../../components/layouts/body/Body";
import { DOMAIN, I18nMessages } from "../../../../i18n/i18n";
import { SelectModal } from "../selectModal/SelectModal";
import { style } from "./ItemActions.style";

interface ItemActionsProps {
  readonly productVariants?: ProductVariantProjection[];
  readonly currentProductVariant: ProductVariantProjection;
  readonly country: Country;
  readonly onShowSizeWithoutStockModal?: () => void;
  readonly onKeep: () => void;
  readonly onReturn: () => void;
  readonly onReplace: (value: string) => void;
  readonly onLayout?: ({ width, height }: LayoutRectangle) => void;
}
const ItemActions: FC<ItemActionsProps> = ({
  productVariants,
  currentProductVariant,
  country,
  onShowSizeWithoutStockModal = () => void 0,
  onKeep,
  onReplace,
  onReturn,
  onLayout,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const changeSizeButtonText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.ITEM_CHANGE_SIZE_BUTTON });
  const keepButtonText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.ITEM_KEEP_BUTTON });
  const returnButtonText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.ITEM_RETURN_BUTTON });

  const sizeSelectorOptions = useMemo(
    () =>
      (productVariants || []).map((productVariant) => ({
        label: size({ size: productVariant.size, country }),
        value: productVariant.id,
      })),
    [country, productVariants],
  );
  const disabledSizeSelector = useMemo(
    () =>
      sizeSelectorOptions.length === 0 ||
      (sizeSelectorOptions.length === 1 && sizeSelectorOptions[0]?.value === currentProductVariant.id),
    [sizeSelectorOptions, currentProductVariant.id],
  );

  const handleOnPressSelectField = useCallback(() => {
    if (disabledSizeSelector) {
      onShowSizeWithoutStockModal();
    } else {
      setModalVisible(true);
    }
  }, [onShowSizeWithoutStockModal, setModalVisible, disabledSizeSelector]);

  const handleOnModalClose = useCallback(() => setModalVisible(false), []);

  return (
    <Sticky style={style.sticky} onLayout={onLayout}>
      <Body style={{ row: style.row }}>
        <View style={style.actionsRow}>
          {!currentProductVariant.size.unique && productVariants ? (
            <Button
              style={style.sizeSelector}
              testID="size-selector"
              variant={BUTTON_VARIANT.SECONDARY}
              small
              onPress={handleOnPressSelectField}
            >
              {changeSizeButtonText}
            </Button>
          ) : null}

          <Button
            style={style.returnButton}
            testID="return-button"
            variant={BUTTON_VARIANT.SECONDARY}
            small
            onPress={onReturn}
          >
            {returnButtonText}
          </Button>
        </View>

        <Button testID="keep-button" small onPress={onKeep}>
          {keepButtonText}
        </Button>
      </Body>
      <SelectModal
        modalVisible={modalVisible}
        options={sizeSelectorOptions}
        portalHostName="Checkout"
        testID="select-field-modal"
        title={changeSizeButtonText}
        value={currentProductVariant.id}
        onChange={onReplace}
        onClose={handleOnModalClose}
      />
    </Sticky>
  );
};

export { ItemActions };
