import { Platform, StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, fontInputPaddingLeft, space2, space4, space6 } = theme();

const INPUT_HEIGHT = 96;

const style = StyleSheet.create({
  input: {
    paddingTop: space4,
    ...Platform.select({
      android: {
        paddingTop: space2,
      },
    }),
  },
  inputContainer: {
    height: INPUT_HEIGHT,
    ...Platform.select({
      android: {
        alignItems: "flex-start",
        paddingLeft: fontInputPaddingLeft,
      },
    }),
  },
  inputField: {
    height: INPUT_HEIGHT,
  },
  wrapper: {
    backgroundColor: colorBgBase,
    padding: space6,
    paddingTop: 0,
  },
});

export { style };
