import React, { createContext, FC, ReactNode, useContext } from "react";
import invariant from "tiny-invariant";
import { QueryBus } from "@lookiero/messaging";

const QueryBusContext = createContext<QueryBus>(null as unknown as QueryBus);

interface QueryBusProviderProps {
  readonly children: ReactNode;
  readonly queryBus: QueryBus;
}

const QueryBusProvider: FC<QueryBusProviderProps> = ({ children, queryBus }) => (
  <QueryBusContext.Provider value={queryBus}>{children}</QueryBusContext.Provider>
);

const useQueryBus = () => {
  const queryBus = useContext(QueryBusContext);

  invariant(
    queryBus,
    "Your are trying to use the useQueryBus hook without wrapping your app with the <QueryBusProvider>.",
  );

  return queryBus;
};

export { useQueryBus, QueryBusProvider };
