import React, { FC } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nNumber } from "@lookiero/i18n-react";
import { PriceProjection } from "../../../../../projection/price/price";
import { DOMAIN } from "../../../i18n/i18n";
import { style } from "./Price.style";

type PriceVariant = "default" | "detail" | "subtotal" | "total";

interface PriceVariantProps {
  readonly body?: boolean;
  readonly detail?: boolean;
  readonly action?: boolean;
  readonly level?: 1 | 2 | 3;
}

const PRICE_VARIANT: Partial<Record<PriceVariant, PriceVariantProps>> = {
  default: { detail: true, level: 1 },
  detail: { detail: true, level: 2 },
  subtotal: { detail: true, level: 1 },
  total: { action: true, level: 2 },
};

interface PriceProps {
  readonly price: PriceProjection;
  readonly variant?: PriceVariant;
  readonly withPercentage?: boolean;
}
const Price: FC<PriceProps> = ({ price, variant = "default", withPercentage = false }) => {
  const isDiscounted = price.discountedPrice && price.discountedPrice.percentage > 0;
  const productPrice = useI18nNumber({
    domain: DOMAIN,
    value: price.amount / 100,
    style: "currency",
    currency: price.currency,
  });
  const productDiscountedPrice = useI18nNumber({
    domain: DOMAIN,
    value: (price.discountedPrice?.amount || 0) / 100,
    style: "currency",
    currency: price.currency,
  });

  const priceValue = isDiscounted ? productDiscountedPrice : productPrice;

  return (
    <View style={style.price} testID="price">
      <Text {...PRICE_VARIANT[variant]} testID="price-text">
        {priceValue}
      </Text>
      {isDiscounted && (
        <View style={style.discountedPriceInfo}>
          <Text
            {...PRICE_VARIANT[variant]}
            style={style.discountedPriceText}
            testID="discounted-price-text"
            lineThrough
          >
            {productPrice}
          </Text>
          {withPercentage ? (
            <Text {...PRICE_VARIANT[variant]} style={style.discountedPercentage} testID="discounted-percentage-text">
              {`-${price.discountedPrice.percentage}%`}
            </Text>
          ) : null}
        </View>
      )}
    </View>
  );
};

export { Price };
