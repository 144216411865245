import React, { FC } from "react";
import { View } from "react-native";
import { COLOR, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { Country } from "@lookiero/sty-psp-locale";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { PriceProjection } from "../../../../../../projection/price/price";
import { SizeProjection, size } from "../../../../../../projection/size/size";
import { Price } from "../../../../components/atoms/price/Price";
import { DOMAIN, I18nMessages } from "../../../../i18n/i18n";
import { style } from "./ProductVariantDescription.style";

interface ProductVariantDescriptionProps {
  readonly brand: string;
  readonly name: string;
  readonly price: PriceProjection;
  readonly size: SizeProjection;
  readonly country: Country;
}
const ProductVariantDescription: FC<ProductVariantDescriptionProps> = ({
  brand,
  name,
  price,
  size: sizeProjection,
  country,
}) => {
  const sizeText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.ITEM_SIZE });
  const uniqueText = useI18nMessage({ domain: DOMAIN, id: I18nMessages.ITEM_UNIQUE });
  const screenDevice = useScreenSize();
  const isDesktopScreen = screenDevice !== "S";

  return (
    <View style={[style.container, isDesktopScreen ? null : style.smallContainer]}>
      <View style={style.info}>
        <View style={style.brand}>
          <Text color={COLOR.TEXT_MEDIUM} level={1} detail>
            {brand}
          </Text>
          <Text level={1} detailBold>
            {name}
          </Text>
        </View>
        <Price price={price} withPercentage />
      </View>
      <Text level={1} detail>
        {`${sizeText} ${sizeProjection.unique ? uniqueText : size({ size: sizeProjection, country })}`}
      </Text>
    </View>
  );
};

export { ProductVariantDescription };
