import React, { FC } from "react";
import { ALIGN, DISPLAY, InfoBox, INFOBOX_TYPE, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { DOMAIN, I18nMessages } from "../../../i18n/i18n";
import { style } from "./FiveItemsDiscountBanner.style";

interface FiveItemsDiscountBannerProps {
  readonly fiveItemsDiscount: number;
}
const FiveItemsDiscountBanner: FC<FiveItemsDiscountBannerProps> = ({ fiveItemsDiscount }) => {
  const bannerText = useI18nMessage({
    domain: DOMAIN,
    id: I18nMessages.BANNER_DISCOUNT_TEXT,
    values: {
      discount: fiveItemsDiscount.toString(),
      b: (chunks: string[]) => (
        <Text as="span" display={DISPLAY.INLINE} level={2} style={style.textBold}>
          {chunks}
        </Text>
      ),
    },
  });

  return (
    <InfoBox
      contentAlign={ALIGN.CENTER}
      icon="info"
      testID="five-items-discount-banner"
      text={bannerText}
      type={INFOBOX_TYPE.SQUARED}
    />
  );
};

export { FiveItemsDiscountBanner };
