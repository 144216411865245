const DOMAIN = "CheckoutI18n";

// TODO: deprecated?
const COLOR_I18N_PREFIX = "catalog.color.";

enum I18nMessages {
  TOAST_GENERIC_ERROR = "toast_generic_error",
  ITEM_SIZE = "item.size",
  ITEM_UNIQUE = "item.unique",
  ITEM_KEEP_BUTTON = "item.keep_button",
  ITEM_RETURN_BUTTON = "item.return_button",
  ITEM_CHANGE_SIZE_BUTTON = "item.change_size_button",
  ITEM_BANNER_CUSTOMER_KEPT_DECISSION = "item.banner_customer_kept_decission",
  ITEM_BANNER_CUSTOMER_REPLACED_DECISSION = "item.banner_customer_replaced_decission",
  ITEM_BANNER_CUSTOMER_RETURNED_DECISSION = "item.banner_customer_returned_decission",
  ITEM_BANNER_BUTTON = "item.banner_button",
  SIZE_CHANGE_MODAL_TITLE = "size_change_modal.title",
  SIZE_CHANGE_MODAL_DESCRIPTION = "size_change_modal.description",
  SIZE_CHANGE_MODAL_BUTTON = "size_change_modal.button",
  SIZE_WITHOUT_STOCK_MODAL_TITLE = "size_without_stock_modal.title",
  SIZE_WITHOUT_STOCK_MODAL_DESCRIPTION = "size_without_stock_modal.description",
  SIZE_WITHOUT_STOCK_MODAL_BUTTON = "size_without_stock_modal.button",
  GET_OUT_OF_CHECKOUT_MODAL_TITLE = "get_out_of_checkout_modal.title",
  GET_OUT_OF_CHECKOUT_MODAL_DESCRIPTION = "get_out_of_checkout_modal.description",
  GET_OUT_OF_CHECKOUT_MODAL_DISMISS_BUTTON = "get_out_of_checkout_modal.dismiss_button",
  GET_OUT_OF_CHECKOUT_MODAL_CONFIRM_BUTTON = "get_out_of_checkout_modal.confirm_button",
  FEEDBACK_TITLE = "return_questions_feedback.feedback.title",
  FEEDBACK_UNANSWERED = "return_questions_feedback.feedback.unanswered",
  RETURN_QUESTIONS_TITLE = "return_questions.title",
  RETURN_QUESTIONS_DESCRIPTION = "return_questions.description",
  RETURN_QUESTIONS_SUBMIT_BUTTON = "return_questions.submit_button",
  RETURN_QUESTION_MAIN_ALL_OPINION = "return_question.main.all.opinion",
  RETURN_QUESTION_TOAST_SUCCESS = "return_question.thankyou",
  SUMMARY_TITLE = "summary.title",
  SUMMARY_DESCRIPTION = "summary.description",
  BANNER_DISCOUNT_TEXT = "summary.banner.text",
  SUMMARY_KEEP_TAB = "summary.keep_tab",
  SUMMARY_RETURN_TAB = "summary.return_tab",
  SUMMARY_KEEP_EMPTY = "summary.keep_empty",
  SUMMARY_RETURN_EMPTY = "summary.return_empty",
  SUMMARY_TOTAL = "summary.total",
  SUMMARY_SUBTOTAL = "summary.subtotal",
  SUMMARY_SUBMIT_BUTTON = "summary.submit_button",
  SUMMARY_FREE = "summary.free",
  SUMMARY_DISCOUNT = "summary.discount",
  SUMMARY_TOTAL_ITEMS_KEPT = "summary.total_items_kept",
  SUMMARY_CREDIT = "summary.credit",
  SUMMARY_FEE = "summary.fee",
  PRODUCT_VARIANT_SIZE_CHANGE = "product_variant.size_change",
  CHECKOUT_TITLE = "checkout.title",
  CHECKOUT_PAY_BUTTON = "checkout.pay_button",
  CHECKOUT_TOAST_PAYMENT_ERROR = "checkout.toast_payment_error",
  CHECKOUT_SUCCESS_MODAL_TITLE = "checkout.success_modal_title",
  CHECKOUT_SUCCESS_MODAL_DESCRIPTION = "checkout.success_modal_description",
  CHECKOUT_SUCCESS_MODAL_BUTTON = "checkout.success_modal_button",
  CHECKOUT_DELIVERY_BANNER = "checkout.delivery_banner",
  HEADER_CHECKOUT_TITLE = "header.checkout_title",
  HEADER_COMPLETE_CHECKOUT_TITLE = "header.complete_checkout_title",
  FEEDBACK_BUTTON = "feedback.button",
  CHECKOUT_TOAST_BOOKING_EXPIRED = "checkout.toast_booking_expired",
}

export { DOMAIN, I18nMessages, COLOR_I18N_PREFIX };
