import React from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { DOMAIN } from "../../../../../i18n/i18n";
import { CheckoutQuestionItem, CheckoutQuestionItemProps } from "../CheckoutQuestionItem";
import { style } from "./HostDefaultCheckoutQuestionItem.style";

const HostDefaultCheckoutQuestionItem: CheckoutQuestionItem = ({
  checkoutQuestion,
  children,
}: CheckoutQuestionItemProps) => {
  const titleText = useI18nMessage({ domain: DOMAIN, id: checkoutQuestion.name });

  return (
    <>
      <Text level={3} style={style.title}>
        {titleText}
      </Text>
      <View style={style.content}>{children}</View>
    </>
  );
};

export { HostDefaultCheckoutQuestionItem };
