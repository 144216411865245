/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18n } from "@lookiero/i18n-react";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import { DOMAIN } from "../../../../../i18n/i18n";
import { ReturnQuestionItem } from "../ReturnQuestionItem";
import { style } from "./HostRadioGroupReturnQuestionItem.style";

const HostRadioGroupReturnQuestionItem: ReturnQuestionItem<ReturnQuestionType.HOST_SELECT> = ({
  returnQuestion,
  children,
  testID,
}) => {
  const { formatMessage } = useI18n({ domain: DOMAIN });
  const placeholderText = useMemo(
    () => (returnQuestion.metadata.placeholder ? formatMessage({ id: returnQuestion.metadata.placeholder }) : ""),
    [formatMessage, returnQuestion.metadata.placeholder],
  );

  return (
    <>
      <View style={style.wrapper} testID={testID}>
        <Text level={3} style={style.title} action>
          {placeholderText}
        </Text>
        {children}
      </View>
    </>
  );
};

export { HostRadioGroupReturnQuestionItem, style };
